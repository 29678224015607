<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />

        <div v-if="init_loading">
            <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card class="card">
                <v-card-title class="heading justify-center"> Total Fine Collected User Wise</v-card-title>



                <div class="">
                    <div class="add-section">
                        <div class="d-flex">
                            <v-col>
                                <label class="add-text">Organization</label>
                                <v-autocomplete v-model="selected_org" placeholder="Select Organization"
                                    :items="org_list" item-text="name" item-value="id" outlined dense class="text"
                                     @change="onLoad()"></v-autocomplete>
                            </v-col>
                            <v-col>
                                <label class="add-text">Academic Year</label>
                                <v-autocomplete v-model="selected_ay" placeholder="Select Academic Year"
                                    :items="ay_list" item-text="ay" item-value="id" outlined dense class="text"
                                    ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <label class="add-text">Program</label>
                                <v-autocomplete multiple v-model="selected_program" placeholder="Select Program" :items="program_list"
                                    item-text="name" item-value="id" outlined dense class="text"
                                   ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <label class="add-text">Year</label>
                                <v-autocomplete multiple v-model="selected_year" placeholder="Select Year" :items="year_list"
                                    item-text="name" item-value="id" outlined dense class="text"
                                   ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <label>Start Date</label>

                                <v-menu v-model="fromDateMenu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y full-width max-width="290px"
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="text" v-model="startDate" placeholder="Select Start Date"
                                            append-icon="event" persistent-hint dense readonly outlined
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="startDate" no-title
                                        @input="fromDateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col>
                                <label>End Date</label>

                                <v-menu v-model="fromDateMenu1" :close-on-content-click="false"
                                    transition="scale-transition" offset-y full-width max-width="290px"
                                    min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="text" v-model="endDate" placeholder="Select End Date"
                                            append-icon="event" persistent-hint dense readonly outlined
                                            v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="endDate" no-title
                                        @input="fromDateMenu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col>
                                <label class="add-text">User Type</label>
                                <v-autocomplete multiple v-model="selected_usertype" placeholder="Select User Type" :items="usertype_list"
                                    item-text="name" item-value="id" outlined class="text" dense
                                    ></v-autocomplete>
                            </v-col>


                        </div>

                        <div>
                            <center>
                                <v-btn color="success" class="mb-4" @click="overdueData()">Fetch Data</v-btn>
                            </center>
                        </div>
                    </div>
                </div>
            </v-card>
            <v-card class="mt-10 mb-10">
                <v-card-title>
                  <v-text-field v-model="search_" append-icon="mdi-magnify" label="Search" single-line
                    hide-details></v-text-field>
                  <v-spacer></v-spacer>
                  <template>
                    <download-excel :data="bl_data" :fields="single_" worksheet="Employee Data"
                      name="Employee Data.xls">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="green" size="40" left v-bind="attrs" v-on="on">mdi-file-excel</v-icon>
                        </template>
                        <span>Import to Excel</span>
                      </v-tooltip>
                    </download-excel>
                  </template>
                </v-card-title>
                <template>
                  <div class="text-center" v-if="overlay">
                    <v-progress-linear indeterminate height="25" color="#393e46">
                      <strong style="color:#FFF;">Loading...</strong>
                    </v-progress-linear>
                  </div>
                </template>
                <v-data-table :headers="headers" :items="bl_data.map((item, index) => ({ ...item, srno: index + 1 }))" :search="search_">
                  <template v-slot:item.receipt_no="{ item }">
                    <!-- <v-icon>mdi-currency-inr</v-icon><span style="font-size:18px">{{ item.fine }}</span> -->
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="gray" v-bind="attrs" v-on="on" dark @click="get_receipt_no_details(item)">
                           <span>{{ item.receipt_no }}</span>
                        </v-chip>
                      </template>
                      <span> View Receipt</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.program="{ item }">
                      <span v-if="item.program">{{ item.program }}</span>
                      <span v-else style="font-size: 20px; font-weight: bold;"> <center>-</center> </span>
                  </template>
                  <template v-slot:item.year="{ item }">
                      <span v-if="item.year">{{ item.year }}</span>
                      <span v-else style="font-size: 20px; font-weight: bold;"> <center>-</center> </span>
                  </template>
                  <template v-slot:item.division="{ item }">
                      <span v-if="item.division">{{ item.division }}</span>
                      <span v-else style="font-size: 20px; font-weight: bold;"> <center>-</center> </span>
                  </template>
                </v-data-table>
              </v-card>
              <v-dialog v-model="receipt_dialog">
                    <v-card >     
                        <receipt_main :key="receipt_main_key" :recno="selected_receipt_no" 
                        ></receipt_main>
                        <center><v-btn @click="receipt_dialog = false" color="primary" class="mb-5">Close</v-btn></center>
                    </v-card>
                </v-dialog>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { table2excel } from "../jquery.table2excel";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import receipt_main from "../../Librarian/receipt_main.vue";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    components: {receipt_main},
    data: () => ({
        init_loading: false,
        receipt_dialog: false,
        selected_receipt_no: "",
        receipt_main_key: "",
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Reports',
                disabled: false,
                href: '/reports',
            },
            {
                text: 'Total Fine Collected User Wise',
                disabled: true,
                href: '',
            },
        ],
        endDate:moment(new Date().toString()).format("YYYY-MM-DD"),
        fromDateMenu1:false,
        startDate:moment().subtract(1, 'months').format("YYYY-MM-DD"),
        fromDateMenu:false,
        program_list:[],
        selected_program:"",
        org_list:[],
        selected_org:"",
        selected_usertype:"",
        usertype_list:"",
        snackbar: false,
        snackbar_msg: "",
        search_: "",
        selected_year: [],
        year_list: [],
        color: "",
        ay_list: [],
        selected_ay: "",
        headers : [
            { text: "Sr. No.", value: "srno" },
            { text: "Name", value: "name" },
            { text: "User Type", value: "usertype" },
            { text: "Program", value: "program" },
            { text: "Division", value: "division", align: "center" },
            { text: "Year", value: "year", align: "center" },
            { text: "Receipt No", value: "receipt_no" },
            { text: "Amount", value: "amount" },
        ],
        bl_data: [],
        
    }),
    computed: {
        fromDateDisp() {
            return this.fromDateVal;
            // format/do something with date
        },

        dateRangeText() {
            return this.dates.join(" ~ ");
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        //End

        exportexcel() {
            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: "Over_Due_Report", //do not include extension
                fileext: ".xls" // file extension
            });
        },
        //End

        init() {
            this.init_loading = true;
            axios
                .post("/ImportData/getOrgList")
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.org_list = res.data.org_list;
                        this.selected_org = res.data.org;
                        this.onLoad();
                    }
                })
        },
        //End

        onLoad() {
            if (!this.selected_org) {
                this.showSnackbar("#b71c1c", "Please Select Organization");
                return;
            }
            var params = { "org": this.selected_org };
            this.init_loading = true;
            axios
                .post("/Librarian/getdeptData", params)
                .then((res) => {
                if (res.data.msg == "200") {
                    this.init_loading = false;
                  
                    this.program_list = res.data.program_list;
                    this.ay_list = res.data.ay_list;
                    this.usertype_list = res.data.usertype_data;
                    this.year_list = res.data.year_list;
                 
                }
            })
        },
        //End

        overdueData() {
            
           

            if(!this.selected_org)
            {
                this.showSnackbar("#b71c1c", "Please Select Organization");
                return;
            }
            if (this.startDate == '' || this.endDate == '' || this.selected_usertype == [] || this.selected_ay=='') {
                this.showSnackbar("#b71c1c", "Please Select  Start Date, End Date, User Type & Academic Year fields are required!!");
            } else {
              
                const data = {
                    firstDate: moment(String(this.startDate)).format("YYYY-MM-DD"),
                    lastDate: moment(String(this.endDate)).format("YYYY-MM-DD"),
                    prog: this.selected_program,
                    year: this.selected_year,
                    user_id: this.selected_usertype,
                    org: this.selected_org,
                    ay_id: this.selected_ay,

                    // colData: this.colData
                };
                this.overlay = true;
                this.load = true;
                axios
                    .post("/LibrarianNew/totalfinecollecteduserwise", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;

                            this.bl_data = res.data.bl_data;
                            this.load = false;

                            if (this.bl_data.length == 0) {
                                this.showSnackbar("#b71c1c", "No data available ");
                            }
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong.."); // show snackbar
                        window.console.log(error);
                        this.load = false;
                    })
                    .finally(() => {
                        this.load = false;
                    });
            }
        },
        //End

        get_receipt_no_details(item){
            this.receipt_main_key++;
            this.receipt_dialog = true;
            this.selected_receipt_no = item.receipt_no;
        },


    },
}
</script>
<style scoped>
.v-text-field {
    padding-top: 0px;
    margin-top: 0px;
}

.v-icon-size {
    font-size: 20px;
}

.edit-avatar {
    transition: 0.3s;
    border: 1px solid #b0bec5;
}

.edit-avatar:hover {
    background-color: #b0bec5;
}

.edit-v-icon {
    padding-left: 6px;
    font-size: 15px;
}

.edit-v-icon:hover {
    color: white;
}

.fields {
    padding: 0px !important;
}

.cus-card {
    background: #d3d3d33d;
    margin: 1rem;
    padding: 1rem;
    border: 2px solid gray;
}

.c-label {
    font-weight: bold;
}

.add-section {
    display: block;
    margin: 1rem;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}

.elevation-1 /deep/ tr {
    white-space: nowrap !important;
}

.d-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem;
}

.checkbox {
    margin: 1rem;
    border: 1px solid;
    background: #d3d3d380;
}

.checkbox .box {
    margin-top: 5px;
}

.checkbox /deep/ .v-input--selection-controls {
    margin-top: 0px !important;
}
</style>
